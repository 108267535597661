import AuthInstance from "~/ts/interfaces/AuthInstance";
import UserDTO, { UserPermission } from "~/ts/models/dto/users/UserDTO";
import { createFetch } from "~/ts/helpers/apiFetch";

export interface AuthMethods {
  login(email: string, password: string): Promise<void>;
  logout(): Promise<void>;
  getAccessToken(): string | null;
  getRefreshToken(): string | null;
  isLoggedIn(): boolean;
  currentUser(): Promise<UserDTO | null>;
  createAuthHeaders(): Promise<{ Authorization: string }>;
  refreshTokenWhenNeeded(): Promise<void>;
  refresh(): Promise<void>;
  hasPermissions(permissions: UserPermission[]): Promise<boolean>;
  requestPasswordReset(email: string): Promise<void>;
  confirmPasswordReset(token: string, newPassword: string): Promise<void>;
}

// const cookie = useCookie<string>("token");


export const useAuth: () => AuthMethods & AuthInstance = () => {
  const tokenStore = useTokenStore("tokens");
  const sessionStore = useSessionStore();

  const apiFetch = createFetch();

  const tokenRefresher = useTokenRefresher(async (access, refresh) => {
    const response = await apiFetch("/auth/refresh",{
      method: "POST",
      jsonBody: {
        access_token: access,
        refresh_token: refresh,
      },
    })
      .then(r => r.json());

    return {
      access: response.access_token,
      refresh: response.refresh_token,
    }
  });

  return <AuthMethods & AuthInstance> {
    async login(email: string, password: string) {
      const response = await apiFetch("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        jsonBody: {
          email,
          password,
        },
      })
        .then(r => r.json());

      tokenStore.set("accessToken", response.access_token);
      tokenStore.set("refreshToken", response.refresh_token);
    },

    async logout() {
      const refresh = await tokenStore.get("refreshToken");

      apiFetch("/auth/revoke", {
        method: "POST",
        jsonBody: {
          refresh_token: refresh,
        },
      });

      tokenStore.remove("accessToken");
      tokenStore.remove("refreshToken");

      sessionStore.remove("userInfo");
    },

    getAccessToken(): string | null {
      return tokenStore.get("accessToken");
    },

    getRefreshToken(): string | null {
      return tokenStore.get("refreshToken");
    },

    isLoggedIn(): boolean {
      return this.getAccessToken() !== null;
    },

    async hasPermissions(permissions: UserPermission[]): Promise<boolean> {
      const currentUser = await this.currentUser();

      if (currentUser === null) {
        return false;
      }

      return permissions.every(permission => currentUser.permissions.includes(permission));
    },

    async currentUser(): Promise<UserDTO | null> {
      if (!this.isLoggedIn()) {
        return null;
      }

      const userInfo = sessionStore.get("userInfo");

      if (userInfo !== null) {
        return <UserDTO> userInfo;
      }

      await this.refreshTokenWhenNeeded();
      const response = await apiFetch("/users/me", {
        headers: {
          ...await this.createAuthHeaders(),
        },
      })
        .then(r => r.json());

      sessionStore.set("userInfo", response);
      return response;
    },

    async createAuthHeaders(): Promise<{ Authorization: string }> {
      await this.refreshTokenWhenNeeded();

      return {
        Authorization: `Bearer ${this.getAccessToken()}`,
      };
    },

    async refreshTokenWhenNeeded() {
      const accessToken = this.getAccessToken();

      if (accessToken === null) {
        return;
      }

      const refreshToken = this.getRefreshToken();

      if (refreshToken === null) {
        return;
      }

      const result = await tokenRefresher.refreshTokenWhenNeeded(
        accessToken,
        refreshToken,
      );

      if (!result) {
        this.logout();
        throw new Error();
      }

      tokenStore.set("accessToken", result.access);
      tokenStore.set("refreshToken", result.refresh);
    },

    async requestPasswordReset(email: string) {
      await apiFetch("/auth/reset-password", {
        method: "POST",
        jsonBody: {
          email,
        },
      });
    },

    async confirmPasswordReset(token: string, newPassword: string) {
      await apiFetch("/auth/confirm-reset", {
        method: "POST",
        jsonBody: {
          reset_token: token,
          new_password: newPassword,
        },
      });
    },
  };
};
