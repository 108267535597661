import { default as _91tool_93TWOd3uXbQ3Meta } from "/home/ploi/nmodata.nl/front-end/pages/[category]/[tool].vue?macro=true";
import { default as indexKWFX9UD2Z8Meta } from "/home/ploi/nmodata.nl/front-end/pages/index.vue?macro=true";
import { default as activatezHAU5D8qh3Meta } from "/home/ploi/nmodata.nl/front-end/pages/management/activate.vue?macro=true";
import { default as confirm_45password_45resetRrDgAjcwenMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/confirm-password-reset.vue?macro=true";
import { default as indexHRTPbdXN3zMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/index.vue?macro=true";
import { default as logint2jbe5RvffMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/login.vue?macro=true";
import { default as request_45password_45resetzPjCjAQM1pMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/request-password-reset.vue?macro=true";
import { default as _91id_93sNrurZCDYAMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/subscriptions/[id].vue?macro=true";
import { default as createdpHkMjlmMVCMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/subscriptions/created.vue?macro=true";
import { default as indexIt34Nv0r5FMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/subscriptions/index.vue?macro=true";
import { default as indexVu1pAbT1ZuMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/edit/index.vue?macro=true";
import { default as mappingsI9f8vtmP0rMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/edit/mappings.vue?macro=true";
import { default as editXI6ZMDCQ7iMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/edit.vue?macro=true";
import { default as _91failedPublicationId_93YBYAbC3YOCMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/failed-publications/[failedPublicationId].vue?macro=true";
import { default as indexbC8R0xnf8hMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/failed-publications/index.vue?macro=true";
import { default as indexPLlCzJZXkxMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/index.vue?macro=true";
import { default as _91publicationId_93QfyRahCagMMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/publications/[publicationId].vue?macro=true";
import { default as index6mSGaTgdlXMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/users/[id]/index.vue?macro=true";
import { default as permissions427nQWVoeQMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/users/[id]/permissions.vue?macro=true";
import { default as _91id_93twgjYoHjGIMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/users/[id].vue?macro=true";
import { default as indexKLYgIOFBIEMeta } from "/home/ploi/nmodata.nl/front-end/pages/management/users/index.vue?macro=true";
import { default as publicatie_45regelsKvozBveh9NMeta } from "/home/ploi/nmodata.nl/front-end/pages/publicatie-regels.vue?macro=true";
import { default as activateYnGk8H6KeuMeta } from "/home/ploi/nmodata.nl/front-end/pages/subscription/activate.vue?macro=true";
import { default as overviewDGqg4jIE2WMeta } from "/home/ploi/nmodata.nl/front-end/pages/subscription/overview.vue?macro=true";
export default [
  {
    name: _91tool_93TWOd3uXbQ3Meta?.name ?? "category-tool",
    path: _91tool_93TWOd3uXbQ3Meta?.path ?? "/:category()/:tool()",
    meta: _91tool_93TWOd3uXbQ3Meta || {},
    alias: _91tool_93TWOd3uXbQ3Meta?.alias || [],
    redirect: _91tool_93TWOd3uXbQ3Meta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/[category]/[tool].vue").then(m => m.default || m)
  },
  {
    name: indexKWFX9UD2Z8Meta?.name ?? "index",
    path: indexKWFX9UD2Z8Meta?.path ?? "/",
    meta: indexKWFX9UD2Z8Meta || {},
    alias: indexKWFX9UD2Z8Meta?.alias || [],
    redirect: indexKWFX9UD2Z8Meta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/index.vue").then(m => m.default || m)
  },
  {
    name: activatezHAU5D8qh3Meta?.name ?? "management-activate",
    path: activatezHAU5D8qh3Meta?.path ?? "/management/activate",
    meta: activatezHAU5D8qh3Meta || {},
    alias: activatezHAU5D8qh3Meta?.alias || [],
    redirect: activatezHAU5D8qh3Meta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/activate.vue").then(m => m.default || m)
  },
  {
    name: confirm_45password_45resetRrDgAjcwenMeta?.name ?? "management-confirm-password-reset",
    path: confirm_45password_45resetRrDgAjcwenMeta?.path ?? "/management/confirm-password-reset",
    meta: confirm_45password_45resetRrDgAjcwenMeta || {},
    alias: confirm_45password_45resetRrDgAjcwenMeta?.alias || [],
    redirect: confirm_45password_45resetRrDgAjcwenMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/confirm-password-reset.vue").then(m => m.default || m)
  },
  {
    name: indexHRTPbdXN3zMeta?.name ?? "management",
    path: indexHRTPbdXN3zMeta?.path ?? "/management",
    meta: indexHRTPbdXN3zMeta || {},
    alias: indexHRTPbdXN3zMeta?.alias || [],
    redirect: indexHRTPbdXN3zMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: logint2jbe5RvffMeta?.name ?? "management-login",
    path: logint2jbe5RvffMeta?.path ?? "/management/login",
    meta: logint2jbe5RvffMeta || {},
    alias: logint2jbe5RvffMeta?.alias || [],
    redirect: logint2jbe5RvffMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/login.vue").then(m => m.default || m)
  },
  {
    name: request_45password_45resetzPjCjAQM1pMeta?.name ?? "management-request-password-reset",
    path: request_45password_45resetzPjCjAQM1pMeta?.path ?? "/management/request-password-reset",
    meta: request_45password_45resetzPjCjAQM1pMeta || {},
    alias: request_45password_45resetzPjCjAQM1pMeta?.alias || [],
    redirect: request_45password_45resetzPjCjAQM1pMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/request-password-reset.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sNrurZCDYAMeta?.name ?? "management-subscriptions-id",
    path: _91id_93sNrurZCDYAMeta?.path ?? "/management/subscriptions/:id()",
    meta: _91id_93sNrurZCDYAMeta || {},
    alias: _91id_93sNrurZCDYAMeta?.alias || [],
    redirect: _91id_93sNrurZCDYAMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: createdpHkMjlmMVCMeta?.name ?? "management-subscriptions-created",
    path: createdpHkMjlmMVCMeta?.path ?? "/management/subscriptions/created",
    meta: createdpHkMjlmMVCMeta || {},
    alias: createdpHkMjlmMVCMeta?.alias || [],
    redirect: createdpHkMjlmMVCMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/subscriptions/created.vue").then(m => m.default || m)
  },
  {
    name: indexIt34Nv0r5FMeta?.name ?? "management-subscriptions",
    path: indexIt34Nv0r5FMeta?.path ?? "/management/subscriptions",
    meta: indexIt34Nv0r5FMeta || {},
    alias: indexIt34Nv0r5FMeta?.alias || [],
    redirect: indexIt34Nv0r5FMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    path: editXI6ZMDCQ7iMeta?.path ?? "/management/tools/:id()/edit",
    children: [
  {
    name: indexVu1pAbT1ZuMeta?.name ?? "management-tools-id-edit",
    path: indexVu1pAbT1ZuMeta?.path ?? "",
    meta: indexVu1pAbT1ZuMeta || {},
    alias: indexVu1pAbT1ZuMeta?.alias || [],
    redirect: indexVu1pAbT1ZuMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: mappingsI9f8vtmP0rMeta?.name ?? "management-tools-id-edit-mappings",
    path: mappingsI9f8vtmP0rMeta?.path ?? "mappings",
    meta: mappingsI9f8vtmP0rMeta || {},
    alias: mappingsI9f8vtmP0rMeta?.alias || [],
    redirect: mappingsI9f8vtmP0rMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/edit/mappings.vue").then(m => m.default || m)
  }
],
    name: editXI6ZMDCQ7iMeta?.name ?? undefined,
    meta: editXI6ZMDCQ7iMeta || {},
    alias: editXI6ZMDCQ7iMeta?.alias || [],
    redirect: editXI6ZMDCQ7iMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: _91failedPublicationId_93YBYAbC3YOCMeta?.name ?? "management-tools-id-failed-publications-failedPublicationId",
    path: _91failedPublicationId_93YBYAbC3YOCMeta?.path ?? "/management/tools/:id()/failed-publications/:failedPublicationId()",
    meta: _91failedPublicationId_93YBYAbC3YOCMeta || {},
    alias: _91failedPublicationId_93YBYAbC3YOCMeta?.alias || [],
    redirect: _91failedPublicationId_93YBYAbC3YOCMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/failed-publications/[failedPublicationId].vue").then(m => m.default || m)
  },
  {
    name: indexbC8R0xnf8hMeta?.name ?? "management-tools-id-failed-publications",
    path: indexbC8R0xnf8hMeta?.path ?? "/management/tools/:id()/failed-publications",
    meta: indexbC8R0xnf8hMeta || {},
    alias: indexbC8R0xnf8hMeta?.alias || [],
    redirect: indexbC8R0xnf8hMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/failed-publications/index.vue").then(m => m.default || m)
  },
  {
    name: indexPLlCzJZXkxMeta?.name ?? "management-tools-id",
    path: indexPLlCzJZXkxMeta?.path ?? "/management/tools/:id()",
    meta: indexPLlCzJZXkxMeta || {},
    alias: indexPLlCzJZXkxMeta?.alias || [],
    redirect: indexPLlCzJZXkxMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91publicationId_93QfyRahCagMMeta?.name ?? "management-tools-id-publications-publicationId",
    path: _91publicationId_93QfyRahCagMMeta?.path ?? "/management/tools/:id()/publications/:publicationId()",
    meta: _91publicationId_93QfyRahCagMMeta || {},
    alias: _91publicationId_93QfyRahCagMMeta?.alias || [],
    redirect: _91publicationId_93QfyRahCagMMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/tools/[id]/publications/[publicationId].vue").then(m => m.default || m)
  },
  {
    path: _91id_93twgjYoHjGIMeta?.path ?? "/management/users/:id()",
    children: [
  {
    name: index6mSGaTgdlXMeta?.name ?? "management-users-id",
    path: index6mSGaTgdlXMeta?.path ?? "",
    meta: index6mSGaTgdlXMeta || {},
    alias: index6mSGaTgdlXMeta?.alias || [],
    redirect: index6mSGaTgdlXMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: permissions427nQWVoeQMeta?.name ?? "management-users-id-permissions",
    path: permissions427nQWVoeQMeta?.path ?? "permissions",
    meta: permissions427nQWVoeQMeta || {},
    alias: permissions427nQWVoeQMeta?.alias || [],
    redirect: permissions427nQWVoeQMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/users/[id]/permissions.vue").then(m => m.default || m)
  }
],
    name: _91id_93twgjYoHjGIMeta?.name ?? undefined,
    meta: _91id_93twgjYoHjGIMeta || {},
    alias: _91id_93twgjYoHjGIMeta?.alias || [],
    redirect: _91id_93twgjYoHjGIMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKLYgIOFBIEMeta?.name ?? "management-users",
    path: indexKLYgIOFBIEMeta?.path ?? "/management/users",
    meta: indexKLYgIOFBIEMeta || {},
    alias: indexKLYgIOFBIEMeta?.alias || [],
    redirect: indexKLYgIOFBIEMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/management/users/index.vue").then(m => m.default || m)
  },
  {
    name: publicatie_45regelsKvozBveh9NMeta?.name ?? "publicatie-regels",
    path: publicatie_45regelsKvozBveh9NMeta?.path ?? "/publicatie-regels",
    meta: publicatie_45regelsKvozBveh9NMeta || {},
    alias: publicatie_45regelsKvozBveh9NMeta?.alias || [],
    redirect: publicatie_45regelsKvozBveh9NMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/publicatie-regels.vue").then(m => m.default || m)
  },
  {
    name: activateYnGk8H6KeuMeta?.name ?? "subscription-activate",
    path: activateYnGk8H6KeuMeta?.path ?? "/subscription/activate",
    meta: activateYnGk8H6KeuMeta || {},
    alias: activateYnGk8H6KeuMeta?.alias || [],
    redirect: activateYnGk8H6KeuMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/subscription/activate.vue").then(m => m.default || m)
  },
  {
    name: overviewDGqg4jIE2WMeta?.name ?? "subscription-overview",
    path: overviewDGqg4jIE2WMeta?.path ?? "/subscription/overview",
    meta: overviewDGqg4jIE2WMeta || {},
    alias: overviewDGqg4jIE2WMeta?.alias || [],
    redirect: overviewDGqg4jIE2WMeta?.redirect || undefined,
    component: () => import("/home/ploi/nmodata.nl/front-end/pages/subscription/overview.vue").then(m => m.default || m)
  }
]