const whitelistedPages = [
  "/management/activate",
  "/management/request-password-reset",
  "/management/confirm-password-reset",
];


export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuth();

  if (!to.path.startsWith("/management")) {
    return;
  }

  const isLoggedIn = auth.isLoggedIn();

  if (whitelistedPages.some(path => to.path.startsWith(path))) {
    return;
  }

  if (to.path === "/management/login" && isLoggedIn) {
    return navigateTo("/management");
  }

  if (to.path.startsWith("/management") && !to.path.startsWith("/management/login") && !isLoggedIn) {
    return navigateTo("/management/login");
  }
});
