export const useTokenStore = (name: string) => {
  const cookie = useCookie<Record<string, string>>(name, {
    default: () => ({}),
    maxAge: 7776000,
    secure: true,
  });

  return {
    set(key: string, value: string) {
      cookie.value[`${name}::${key}`] = value;
    },

    get(key: string): string | null {
      return cookie.value[`${name}::${key}`] ?? null;
    },

    remove(key: string) {
      delete cookie.value[`${name}::${key}`];
    },
  }
};
