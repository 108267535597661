import AsyncLock from "~/ts/helpers/AsyncLock";
import JWTHelper from "~/ts/helpers/JWTHelper";

const refreshLock = new AsyncLock();

interface UseTokenRefresher {
  refreshTokenWhenNeeded(access: string, refresh: string): Promise<TokenCallbackResult | null>;
  refresh(access: string, refresh: string): Promise<TokenCallbackResult | null>;
}

interface TokenCallbackResult {
  access: string;
  refresh: string;
}

type TokenRefreshCallback = (access: string, refresh: string) => Promise<TokenCallbackResult>;

export const useTokenRefresher: (callback: TokenRefreshCallback) => UseTokenRefresher = (callback) => {
  return {
    async refreshTokenWhenNeeded(access: string, refresh: string): TokenCallbackResult | null {
      try {
        // Await current promise
        await refreshLock.lock();
        const tokenPayload = JWTHelper.getJWTClaims(access);

        const expireDate = new Date(tokenPayload.exp);
        if (expireDate.getTime() * 1000 > Date.now()) {
          refreshLock.release();
          return {
            access,
            refresh,
          }; // Not expired
        }

        const result = await this.refresh(access, refresh);

        refreshLock.release();
        return result;
      } catch(_) {
        refreshLock.release();
      }

      return null;
    },

    async refresh(access: string, refresh: string): TokenCallbackResult | null {
      // const token = await this.getAccessToken();
      // const refreshToken = await this.getRefreshToken();

      if (refresh === null) {
        throw new Error("Cannot refresh token");
      }

      const refreshPayload = JWTHelper.getJWTClaims(refresh);

      const refreshExpireDate = new Date(refreshPayload.exp);

      if (refreshExpireDate.getTime() * 1000 < Date.now()) {
        throw new Error("Refresh token has expired");
      }

      try {
        return await callback(
          access,
          refresh,
        );
      } catch (_) {
        return null;
      }
    }
  };
};
