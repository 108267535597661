import AuthInstance from "~/ts/interfaces/AuthInstance";
import APIError from "~/ts/models/errors/APIError";
import { RuntimeConfig } from "nuxt/schema";

export interface CustomRequestInit extends RequestInit {
  auth?: AuthInstance,
  jsonBody?: object,
}

export function createFetch() {
  const config = useRuntimeConfig();
  return async (input: string, init: CustomRequestInit = {}) => apiFetch(input, config, init);
}

async function apiFetch(input: string, config: RuntimeConfig, init: CustomRequestInit = {}): Promise<Response> {
  if (input.startsWith("/")) {
    input = config.public.baseApiPath + input;
  }

  if (init.auth && init.auth.isLoggedIn()) {
    if (init.headers === undefined) {
      init.headers = {};
    }

    init.headers = {
      ...await init.auth.createAuthHeaders(),
      ...init.headers,
    }

    delete init.auth;
  }

  if (init.jsonBody) {
    if (init.headers === undefined) {
      init.headers = {};
    }

    init.headers = {
      "Content-Type": "application/json",
      ...init.headers,
    };

    init.body = JSON.stringify(init.jsonBody);
  }

  const response = await fetch(input, init);

  if (!response.ok) {
    throw await APIError.fromResponse(response);
  }

  return response;
}
