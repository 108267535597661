export default defineNuxtRouteMiddleware(() => {
  if (!process.client) {
    return;
  }

  // Nuxt SSR together with Tairo do some weird things, so sadly this is needed to ensure this is run after whatever
  // Tairo is doing.
  setTimeout(() => {
    const colorMode = localStorage.getItem("nuxt-color-mode");

    if (colorMode !== "light") {
      localStorage.setItem("nuxt-color-mode", "light");
      location.reload();
    }
  });
})
