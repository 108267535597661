import ApplicationError from "~/ts/models/errors/ApplicationError";

export default class APIError extends ApplicationError {
  static async fromResponse(response: Response) {
    let message = `Request failed (status ${response.status})`;

    try {
      const json = await response.json();

      if (json["message"]) {
        message = json["message"];
      }
    } catch (_) {
      // Intentionally left empty
    }

    return new APIError(message);
  }
}
