import revive_payload_client_4sVQNw7RlN from "/home/ploi/nmodata.nl/front-end/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/ploi/nmodata.nl/front-end/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/ploi/nmodata.nl/front-end/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ploi/nmodata.nl/front-end/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/ploi/nmodata.nl/front-end/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_i8AMfKeYnY from "/home/ploi/nmodata.nl/front-end/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_m51yvfcbqo from "/home/ploi/nmodata.nl/front-end/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/ploi/nmodata.nl/front-end/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/home/ploi/nmodata.nl/front-end/node_modules/nuxt/dist/app/plugins/payload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_client_i8AMfKeYnY,
  plugin_m51yvfcbqo,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl
]