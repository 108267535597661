export const useSessionStore = () => {
  const cookie = useCookie<Record<string, unknown>>("session", {
    default: () => ({}),
  });

  return {
    set<T extends object>(key: string, value: T) {
      cookie.value[key] = value;
    },

    get<T extends object>(key: string): T | null {
      return <T> cookie.value[key] ?? null;
    },

    remove(key: string) {
      delete cookie.value[key];
    }
  }
};
